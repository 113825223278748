import { useEffect } from 'react';
import Container from 'typedi';

import { useAuthContext } from 'contexts/AuthContext';
import { useAppContext } from 'contexts/AppContext';
import { useLocation } from 'react-router';
import NavigationLogger from 'services/Loggers/NavigationLogger';
import { usePrevious } from '@just-ai/just-ui';

const navigationLogger = Container.get(NavigationLogger);

const useNavigationLogger = () => {
  const { user } = useAuthContext();
  const { saveClicksEnabled } = useAppContext();
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    navigationLogger.setUser(user);
  }, [user]);

  useEffect(() => {
    if (saveClicksEnabled) {
      const prevUrl = prevLocation ? prevLocation.pathname + prevLocation.search : '';
      const nextUrl = location.pathname + location.search;
      navigationLogger.logNavigation({ prevUrl, nextUrl });
    }
  }, [
    location.pathname,
    location.search,
    prevLocation,
    prevLocation?.pathname,
    prevLocation?.search,
    saveClicksEnabled,
  ]);
};

export default useNavigationLogger;
