import { PersistentJobStatusEnum } from 'api/CailagateApi/api/client';

export const shotJobStatusStyleMap: Record<PersistentJobStatusEnum, { iconColor: string; iconName: string }> = {
  [PersistentJobStatusEnum.IDLE]: { iconColor: 'lightblue', iconName: 'farHourglassHalf' },
  [PersistentJobStatusEnum.RUN]: { iconColor: '#5492CD', iconName: 'farHourglassHalf' },
  [PersistentJobStatusEnum.REVERTING]: { iconColor: '#5492CD', iconName: 'farUndoAlt' },
  [PersistentJobStatusEnum.COMPLETED]: { iconColor: 'green', iconName: 'farCheckCircle' },
  [PersistentJobStatusEnum.FAILED]: { iconColor: '#C14646', iconName: 'farExclamationCircle' },
  [PersistentJobStatusEnum.REVERTED]: { iconColor: '#E6C323', iconName: 'farExclamationTriangle' },
  [PersistentJobStatusEnum.REPLACED]: { iconColor: 'darkorange', iconName: 'farRepeat' },
};

export const DEFAULT_ICON_STYLE = { iconColor: '#5492CD', iconName: 'farHourglassHalf' };

type AccountEntityType = {
  key: {
    accountId: number;
  };
  name: 'account';
};

type InstanceEntityType = {
  key: {
    accountId: number;
    modelId: number;
    instanceId: number;
  };
  name: 'instance';
};

type ModelEntityType = {
  key: {
    accountId: number;
    modelId: number;
  };
  name: 'model';
};

type PrototypeEntityType = {
  key: {
    accountId: number;
    modelId: number;
  };
  name: 'prototype';
};

type GroupEntityType = {
  key: {
    groupOwnerId: number;
    groupName: string;
  };
  name: 'resource_group';
};

type ServerEntityType = {
  key: {
    groupOwnerId: number;
    groupName: string;
    serverId: number;
  };
  name: 'resource_group_server';
};

type QuotaEntityType = {
  key: {
    groupOwnerId: number;
    groupName: string;
  };
  name: 'resource_quota';
};

type RuntimeEnvironmentEntity = {
  key: {
    accountId: number;
  };
  name: 'runtime_environment';
};

type K8sDeploymentEntityType = {
  key: {
    accountId: number;
    modelId: number;
  };
  name: 'k8s_deployment';
};

type DockerContainerEntityType = {
  key: {
    accountId: number;
    modelId: number;
    instanceId: number;
  };
  name: 'docker_container';
};

type HostingServerInstanceEntityType = {
  key: {
    accountId: number;
    modelId: number;
    instanceId: number;
  };
  name: 'hs_instance';
};

export type EntityTypes =
  | AccountEntityType
  | InstanceEntityType
  | ModelEntityType
  | PrototypeEntityType
  | RuntimeEnvironmentEntity
  | GroupEntityType
  | QuotaEntityType
  | ServerEntityType
  | K8sDeploymentEntityType
  | DockerContainerEntityType
  | HostingServerInstanceEntityType;

export type EntityTypeNames = EntityTypes['name'];
