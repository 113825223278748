import React, { useCallback, useEffect, useRef } from 'react';
import { Popover, Tabs } from '@just-ai/just-ui';

import Button from 'components/Button';

import { useToggle } from 'utils/hooks';

import styles from './styles.module.scss';

interface TabType<TabValueType extends string> {
  name: string;
  value: TabValueType;
  dataTestId: string;
}

interface PopoverWithTabsInterface<TabValueType extends string> {
  activeTab: string;
  setActiveTab: (value: TabValueType) => void;
  tabValues: TabType<TabValueType>[];
  onClose?: () => void;
  children: React.ReactChild;
  buttonContent: React.ReactNode;
  title: string;
  popoverId: string;
  ['toggle-data-test-id']?: string;
}

const PopoverWithTabs = <TabValueType extends string>({
  children,
  activeTab,
  setActiveTab,
  tabValues,
  onClose,
  title,
  popoverId,
  buttonContent,
  ...props
}: PopoverWithTabsInterface<TabValueType>) => {
  const [isOpen, , close, toggleOpen] = useToggle(false);

  const popoverTargetId = `target-${popoverId}`;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClose = useCallback(() => {
    onClose?.();
    close();
  }, [close, onClose]);

  const checkClickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e?.target as HTMLElement;
      const popover = document.getElementById(popoverId);

      if (
        popover &&
        target &&
        buttonRef?.current &&
        !popover.contains(target as Node) &&
        !buttonRef.current.contains(target as Node)
      ) {
        handleClose();
      }
    },
    [handleClose, popoverId]
  );

  useEffect(() => {
    isOpen && document.addEventListener('click', checkClickOutside);
    return () => document.removeEventListener('click', checkClickOutside);
  }, [checkClickOutside, isOpen]);

  return (
    <div>
      <Button
        className={styles.popoverWithTabs__bttn}
        innerRef={buttonRef}
        id={popoverTargetId}
        data-test-id={props?.['toggle-data-test-id']}
        onClick={() => toggleOpen()}
      >
        {buttonContent}
      </Button>
      <Popover
        isOpen={isOpen}
        target={popoverTargetId}
        placement='bottom'
        title={title}
        id={popoverId}
        onCancelClick={() => handleClose()}
        className={styles.popoverWithTabs__popover}
      >
        <div className={styles.popoverWithTabs__tabs}>
          <Tabs
            tabs={tabValues}
            activeTab={activeTab}
            name='tabs'
            onChange={tab => setActiveTab(tab as TabValueType)}
            compact
          />
        </div>
        <div className={styles.popoverWithTabs__container}>{children}</div>
      </Popover>
    </div>
  );
};

PopoverWithTabs.displayName = 'PopoverWithTabs';

export default React.memo(PopoverWithTabs) as typeof PopoverWithTabs;
