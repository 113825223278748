import { useCallback, useMemo, useState } from 'react';
import { Icon } from '@just-ai/just-ui';

import NoContent from 'components/NoContent';

import { useNotificationsContext } from '../../domain/context';

import Notification from '../Notification';

import { t } from 'localization';

import PopoverWithTabs from 'components/PopoverWithTabs';
import { ElementWithStatus } from 'components/StatusIndicator';
import { StatusType } from 'components/StatusIndicator/types';
import { SeverityEnum, TypeEnum } from 'api/CailagateApi/api/client';

import styles from './styles.module.scss';

const popoverId = 'notifications-toggle';

type TabValueType = 'all' | 'info' | 'errors';

type TabType = {
  name: string;
  value: TabValueType;
  dataTestId: string;
};

const NotificationsPopover = () => {
  const { notifications: fetchedNotifications, saveNotificationAsViewed } = useNotificationsContext();

  const [activeTab, setActiveTab] = useState<TabValueType>('all');

  const tabValues: TabType[] = [
    {
      name: t('Notifications:Tabs:All'),
      value: 'all',
      dataTestId: 'Notifications:Tabs:All',
    },
    {
      name: t('Notifications:Tabs:Info'),
      value: 'info',
      dataTestId: 'Notifications:Tabs:Info',
    },
    {
      name: t('Notifications:Tabs:Errors'),
      value: 'errors',
      dataTestId: 'Notifications:Tabs:Errors',
    },
  ];

  const handleClose = useCallback(() => {
    saveNotificationAsViewed();
  }, [saveNotificationAsViewed]);

  const notifications = fetchedNotifications.filter(
    notification =>
      notification.type !== TypeEnum.MODELEVENTSERRORINDICATOR &&
      notification.type !== TypeEnum.SERVEREVENTSERRORINDICATOR
  );

  const notificationsPopoverStatus: StatusType | undefined = (() => {
    const unviewedNotifications = notifications.filter(notification => !notification.readAt);
    if (!unviewedNotifications?.length) return undefined;

    return unviewedNotifications.some(notification => notification.severity === SeverityEnum.ERROR)
      ? StatusType.DANGER
      : unviewedNotifications.some(notification => notification.severity === SeverityEnum.WARNING)
      ? StatusType.WARNING
      : StatusType.INFO;
  })();

  const notificationsToRender = useMemo(() => {
    switch (activeTab) {
      case 'all': {
        return notifications;
      }
      case 'info': {
        return notifications.filter(
          notification => notification.severity === SeverityEnum.INFO || notification.severity === SeverityEnum.WARNING
        );
      }
      case 'errors': {
        return notifications.filter(notification => notification.severity === SeverityEnum.ERROR);
      }
    }
    return notifications;
  }, [activeTab, notifications]);

  return (
    <PopoverWithTabs
      activeTab={activeTab}
      title={t('Notifications:Title')}
      setActiveTab={value => setActiveTab(value)}
      tabValues={tabValues}
      popoverId={popoverId}
      toggle-data-test-id='Notifications:OpenToggleButton'
      buttonContent={
        <ElementWithStatus element={<Icon color='secondary' name='farBell' />} status={notificationsPopoverStatus} />
      }
      onClose={handleClose}
    >
      <>
        {!notificationsToRender?.length ? (
          <NoContent className={styles.notificationsPopover__noContent} message={t('Notifications:NoContentMessage')} />
        ) : (
          notificationsToRender?.map((notificationData, index) => (
            <Notification key={notificationData.id} data={notificationData} />
          ))
        )}
      </>
    </PopoverWithTabs>
  );
};

export default NotificationsPopover;
