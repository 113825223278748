import * as BackgroundTasksModule from 'modules/BackgroundTasks';

const BackgroundTasks = () => {
  return (
    <BackgroundTasksModule.BackgroundTasksContextProviderComponent>
      <BackgroundTasksModule.TasksPopover />
    </BackgroundTasksModule.BackgroundTasksContextProviderComponent>
  );
};

export default BackgroundTasks;
