import React from 'react';
import { t } from 'localization';

import styles from './styles.module.scss';

interface ErrorLabelInterface {
  message?: string;
}

const ErrorLabel = ({ message }: ErrorLabelInterface) => {
  if (!message) return null;

  return <span className={styles.error}>{`${t('BackgroundTasks:Task:ErrorLabel')}: ${message}`}</span>;
};

export default React.memo(ErrorLabel);
