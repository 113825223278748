import { useCallback, useState } from 'react';

import { PersistentJobStatusEnum, TaskShortData } from 'api/CailagateApi/api/client';
import { t } from 'localization';
import { getDurationLabel } from 'modules/BackgroundTasks/features/Task/utils';

import useInterval from 'hooks/useInterval';
import { useBackgroundTasksContext } from 'modules/BackgroundTasks/domain/context';
import { useIsVisible } from 'hooks/useIsVisible';
import usePromiseProcessing from 'utils/hooks/usePromiseProcessing';

import { DateTime } from 'luxon';

export const useTaskVisibilityObserver = (targetNodeRef: React.RefObject<HTMLDivElement>, jobId: string) => {
  const { addViewedTask } = useBackgroundTasksContext();

  const handleVisibilityChange = useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        addViewedTask(jobId);
      }
    },
    [addViewedTask, jobId]
  );

  useIsVisible(targetNodeRef, handleVisibilityChange);
};

export const useTaskStop = (jobId: string) => {
  const { stopTask } = useBackgroundTasksContext();
  const [{ loading }, stop] = usePromiseProcessing(() => stopTask(jobId), [stopTask, jobId]);
  return { loading, stop };
};

export const useTaskDuration = (task: TaskShortData, disabled: boolean = false) => {
  const [taskDuration, setTaskDuration] = useState(0);

  useInterval(
    () => {
      const start = task.runAt;
      if (start) {
        const now = Date.now();
        setTaskDuration(now - start);
      }
    },
    1000,
    { shouldCallImmediately: true, disabled }
  );

  return taskDuration;
};

export const useTimeLabel = (task: TaskShortData) => {
  const taskIsInProgress =
    task.persistentJobStatus === PersistentJobStatusEnum.RUN ||
    task.persistentJobStatus === PersistentJobStatusEnum.IDLE;

  const taskDuration = useTaskDuration(task, !taskIsInProgress);

  switch (task.persistentJobStatus) {
    case PersistentJobStatusEnum.RUN:
    case PersistentJobStatusEnum.IDLE: {
      return task.runAt
        ? t('BackgroundTasks:Task:RunAt', {
            time: DateTime.fromMillis(task.runAt).toFormat('HH:mm:ss'),
            duration: getDurationLabel(taskDuration),
          })
        : null;
    }
    case PersistentJobStatusEnum.COMPLETED: {
      return task.endTime && task.runAt
        ? `${DateTime.fromMillis(task.endTime).toFormat('HH:mm:ss')}, ${getDurationLabel(task.endTime - task.runAt)}`
        : null;
    }
    case PersistentJobStatusEnum.FAILED: {
      return task.endTime ? DateTime.fromMillis(task.endTime).toFormat('HH:mm:ss') : null;
    }
    case PersistentJobStatusEnum.REVERTED: {
      //INFO: task with reverted status type should also ahve interrupted === true flag - it's an error if it's not
      if (!task.interrupted && !task.errorMessage) return null;

      return task.endTime
        ? t('BackgroundTasks:Task:EndTime', {
            time: DateTime.fromMillis(task.endTime).toFormat('HH:mm:ss'),
          })
        : null;
    }
  }

  return null;
};
