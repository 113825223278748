import { DomainOptions, AccountDataDto } from 'contexts/AuthContext/types';
import { AxiosError } from 'axios';
type UserData = {
  accountData: {
    internal: boolean;
    accountId?: number;
    accountOwner: boolean;
  };
  domainData: DomainOptions;
  allowedAccountsData: AccountDataDto[];
};

function hasErrorProperty(obj: unknown): obj is { error: unknown } {
  return obj instanceof Object && 'error' in obj;
}

export default class AccountInProductVerifier {
  public errorInterceptor(error: AxiosError, triggerErrors: string[] = []) {
    const requestErrorObj = error.response?.data;
    const errorStatus = error.response?.status;

    const errorCode = hasErrorProperty(requestErrorObj) ? (requestErrorObj?.error as string) : '';

    if (errorStatus === 403 && (triggerErrors.length === 0 || triggerErrors.includes(errorCode))) {
      this.redirectToSelectAccount();
    }
  }

  checkAccountInfo(data: UserData) {
    const { accountData, domainData, allowedAccountsData } = data;
    const internalUiAvailable = domainData?.internalUserUiAvailable;

    const isInternalUser = accountData?.internal;
    const accountId = accountData?.accountId;

    if (isInternalUser && internalUiAvailable) return;
    if (!accountId && (!isInternalUser || (isInternalUser && !internalUiAvailable))) {
      this.redirectToSelectAccount();
      return;
    }

    const allowedAccountsLength = allowedAccountsData?.length;
    const currentAccountFromAllowedAccounts = allowedAccountsData?.find(account => account.id === accountId);

    const isCurrentUserOwner = accountData?.accountOwner;

    if (
      !accountId ||
      !allowedAccountsLength ||
      !currentAccountFromAllowedAccounts?.availableToSelect ||
      (domainData.ownerOnly && !isCurrentUserOwner)
    ) {
      this.redirectToSelectAccount();
    }
  }

  private redirectToSelectAccount() {
    window.location.href =
      (window as any)?.isDev || process.env.NODE_ENV === 'development'
        ? 'https://localhost:3001/c/select-account'
        : '/c/select-account';
  }
}
