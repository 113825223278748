import { useCallback, useState } from 'react';
import i18n from 'localization';

import { Settings } from 'luxon';
import { LanguageEnum } from 'api/CailagateApi/api/client';
import { getUserLanguage } from 'utils';

export const DEFAULT_APP_LANGUAGE = LanguageEnum.EN;

export const useAppLanguage = (): [language: LanguageEnum, setLanguage: (language?: LanguageEnum) => Promise<void>] => {
  const [language, setLanguage] = useState<LanguageEnum>(DEFAULT_APP_LANGUAGE);

  const changeLanguage = useCallback(async (language?: LanguageEnum) => {
    const userLanguage = getUserLanguage(language);
    Settings.defaultLocale = userLanguage;
    await i18n.changeLanguage(userLanguage.toLowerCase());
    setLanguage(userLanguage);
    //TODO: axios headers language update
  }, []);

  return [language, changeLanguage];
};
