import React from 'react';
import cn from 'classnames';

import { Icon } from '@just-ai/just-ui/dist/Icon';

import styles from './styles.module.scss';

export type InfoBannerType = 'warning' | 'danger' | 'info';

interface InfoBannerInterface {
  type?: InfoBannerType;
  message?: string | React.ReactNode;
  actionRender?: () => React.ReactNode;
  ['data-test-id']?: string;
  className?: string;
  messageClassName?: string;
}

const InfoBanner = ({
  type = 'warning',
  message,
  actionRender,
  className,
  messageClassName,
  ...props
}: InfoBannerInterface) => {
  return (
    <div className={cn(styles[`banner-${type}`], className)} data-test-id={props?.['data-test-id']}>
      <Icon name='farExclamationCircle' color={type} size='lg' />
      <span className={cn(messageClassName)}>{message}</span>
      {actionRender?.()}
    </div>
  );
};

export default React.memo(InfoBanner);
