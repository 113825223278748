import { Duration } from 'luxon';
import { EntityTypes } from '../../domain/types';
import { WORKSPACE_BASE_PATH } from '../../external';

export const getDurationLabel = (duration: number): string => {
  const elapsed = Duration.fromMillis(duration).shiftTo('hours', 'minutes', 'seconds');

  return [
    elapsed.hours ? `${elapsed.hours}h` : '',
    elapsed.minutes ? `${elapsed.minutes}m` : '',
    elapsed.seconds || (!elapsed.hours && !elapsed.minutes) ? `${Math.ceil(elapsed.seconds)}s` : '',
  ]
    .filter(Boolean)
    .join(' ');
};

export const getEntityLink = (data: EntityTypes): string | undefined => {
  if (!data || !data.name || !data.key) return undefined;
  switch (data.name) {
    case 'model': {
      return `/${WORKSPACE_BASE_PATH}/model/${data.key?.accountId}/${data.key?.modelId}`;
    }
    case 'prototype': {
      return `/${WORKSPACE_BASE_PATH}/model/${data.key?.accountId}/${data.key?.modelId}`;
    }
    case 'resource_group': {
      return `/${WORKSPACE_BASE_PATH}/resource-group/${data.key?.groupOwnerId}/${data.key?.groupName}`;
    }
    case 'resource_group_server': {
      return `/${WORKSPACE_BASE_PATH}/resource-group/${data.key?.groupOwnerId}/${data.key?.groupName}/servers`;
    }
    case 'resource_quota': {
      return `/${WORKSPACE_BASE_PATH}/resource-group/${data.key?.groupOwnerId}/${data.key?.groupName}/quotas`;
    }
    case 'instance': {
      //TODO add instance selection by query param when it will be supported
      return `/${WORKSPACE_BASE_PATH}/model/${data.key?.accountId}/${data.key?.modelId}/diagnostic/monitoring`;
    }
  }
  return undefined;
};
