import { useCallback, useMemo, useState } from 'react';
import { Icon } from '@just-ai/just-ui';

import NoContent from 'components/NoContent';
import TasksList from '../TasksList';
import PopoverWithTabs from 'components/PopoverWithTabs';
import { ElementWithStatus } from 'components/StatusIndicator';

import { useBackgroundTasksContext } from '../../domain/context';

import { StatusType } from 'components/StatusIndicator/types';
import { PersistentJobStatusEnum } from 'api/CailagateApi/api/client';
import { t } from 'localization';

import styles from './styles.module.scss';

const popoverId = 'background-tasks-toggle';

type TabValueType = 'all' | 'progress' | 'completed';

type TabType = {
  name: string;
  value: TabValueType;
  dataTestId: string;
};

const TasksPopover = () => {
  const { tasks: fetchedTasks, saveTasksAsViewed } = useBackgroundTasksContext();

  const [activeTab, setActiveTab] = useState<TabValueType>('all');

  const tabValues: TabType[] = [
    {
      name: t('BackgroundTasks:Tabs:All'),
      value: 'all',
      dataTestId: 'BackgroundTasks.Tabs.All',
    },
    {
      name: t('BackgroundTasks:Tabs:InProgress'),
      value: 'progress',
      dataTestId: 'BackgroundTasks:Tabs:InProgress',
    },
    {
      name: t('BackgroundTasks:Tabs:Completed'),
      value: 'completed',
      dataTestId: 'BackgroundTasks:Tabs:Completed',
    },
  ];

  const handleClose = useCallback(() => {
    saveTasksAsViewed();
  }, [saveTasksAsViewed]);

  const tasks = fetchedTasks;

  const tasksPopoverStatus: StatusType | undefined = (() => {
    const unviewedTasks = tasks.filter(task => task.unreadStatus);
    if (!unviewedTasks?.length) return undefined;

    return unviewedTasks.some(task => task.persistentJobStatus === PersistentJobStatusEnum.FAILED)
      ? StatusType.DANGER
      : unviewedTasks.some(task =>
          [PersistentJobStatusEnum.IDLE, PersistentJobStatusEnum.RUN, PersistentJobStatusEnum.REVERTING].includes(
            task.persistentJobStatus
          )
        )
      ? StatusType.INFO
      : undefined;
  })();

  const tasksToRender = useMemo(() => {
    switch (activeTab) {
      case 'all': {
        return tasks;
      }
      case 'completed': {
        return tasks.filter(task =>
          [
            PersistentJobStatusEnum.COMPLETED,
            PersistentJobStatusEnum.FAILED,
            PersistentJobStatusEnum.REVERTED,
          ].includes(task.persistentJobStatus)
        );
      }
      case 'progress': {
        return tasks.filter(task =>
          [PersistentJobStatusEnum.IDLE, PersistentJobStatusEnum.RUN, PersistentJobStatusEnum.REVERTING].includes(
            task.persistentJobStatus
          )
        );
      }
    }
    return tasks;
  }, [activeTab, tasks]);

  return (
    <PopoverWithTabs
      activeTab={activeTab}
      title={t('BackgroundTasks:Title')}
      setActiveTab={value => setActiveTab(value)}
      tabValues={tabValues}
      popoverId={popoverId}
      toggle-data-test-id='BackgroundTasks:OpenToggleButton'
      buttonContent={
        <ElementWithStatus element={<Icon color='secondary' name='farClipboardList' />} status={tasksPopoverStatus} />
      }
      onClose={handleClose}
    >
      {!tasksToRender?.length ? (
        <NoContent className={styles.backgroundTasks__noContent} message={t('BackgroundTasks:NoContentMessage')} />
      ) : (
        <TasksList tasks={tasksToRender} />
      )}
    </PopoverWithTabs>
  );
};

TasksPopover.displayName = 'TasksPopover';

export default TasksPopover;
