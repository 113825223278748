import React from 'react';
import { Link } from 'react-router-dom';

import { TaskShortData } from 'api/CailagateApi/api/client';
import { EntityTypes } from 'modules/BackgroundTasks/domain/types';
import i18n, { t } from 'localization';

import { getEntityLink } from 'modules/BackgroundTasks/features/Task/utils';

interface TitleInterface {
  task: TaskShortData;
}

const Title = ({ task }: TitleInterface) => {
  const operatedEntity = task.operatedEntity as EntityTypes;

  const operation = t(`BackgroundTasks:Task:Title:Operation:${task.operation}`);
  const entity = t(`BackgroundTasks:Task:Title:Operation:${operatedEntity.name}`);
  const name = task.operatedEntityName || '';
  const link = getEntityLink(operatedEntity);
  const reason = i18n.exists(`BackgroundTasks:Task:Title:Operation:${task.operation}:Reason`)
    ? t(`BackgroundTasks:Task:Title:Operation:${task.operation}:Reason`)
    : '';

  const reasonText = reason ? ` ${reason}` : '';

  return link ? (
    name ? (
      <>
        {`${operation} ${entity} `}
        <Link to={link}>{name}</Link>
        {reasonText}
      </>
    ) : (
      <>
        {`${operation} `}
        <Link to={link}>{entity}</Link>
        {reasonText}
      </>
    )
  ) : (
    <>{`${operation} ${entity} ${name}${reasonText}`}</>
  );
};

export default React.memo(Title);
