import * as NotificationsModule from 'modules/Notifications';

const Notifications = () => {
  return (
    <NotificationsModule.NotificationsContextProviderComponent>
      <NotificationsModule.NotificationsPopover />
    </NotificationsModule.NotificationsContextProviderComponent>
  );
};

export default Notifications;
