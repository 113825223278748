import { useDI } from 'contexts/AppContext';
import { useCallback } from 'react';
import { AbortControllerService } from 'services/AbortControllerService';
import { TaskApiService } from 'services/ApiServices/TaskApiService';
import { AppLogger } from 'services/AppLogger';

const useBackgroundTasksApi = () => {
  const taskApi = useDI(TaskApiService);
  const abortController = useDI(AbortControllerService);

  const getTaskStatuses = useCallback(async () => {
    try {
      const { data } = await abortController.get('BackgroundTasks:getTaskStatuses', signal =>
        taskApi.shortJobsView(undefined, { signal })
      );
      return data;
    } catch (error) {
      AppLogger.log({ exception: error });
    }
  }, [abortController, taskApi]);

  const markRead = useCallback(
    async (jobIds: string[]) => {
      try {
        taskApi.markRead(jobIds);
      } catch (error) {
        AppLogger.log({ exception: error });
      }
    },
    [taskApi]
  );

  const stopTask = useCallback(
    async (taskId: string) => {
      try {
        await taskApi.stopJob(taskId);
        await getTaskStatuses();
      } catch (error) {
        AppLogger.log({ exception: error });
      }
    },
    [getTaskStatuses, taskApi]
  );

  return { getTaskStatuses, markRead, stopTask };
};

export default useBackgroundTasksApi;
