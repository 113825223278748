import { Service } from 'typedi';

import { TaskEndpointApi } from 'api/CailagateApi/api/client';

@Service()
export class TaskApiService extends TaskEndpointApi {
  constructor() {
    super({ baseOptions: { timeout: 30000 } }, '');
  }
}
