import React, { useRef } from 'react';
import { Icon } from '@just-ai/just-ui/dist/Icon';

import Title from './components/Title';

import { UINotificationData, SeverityEnum } from 'api/CailagateApi/api/client';

import { DateTime } from 'luxon';
import { useNotificationVisibilityObserver } from './hooks';

import styles from './styles.module.scss';

interface NotificationInterface {
  data: UINotificationData;
}

const styleSeverityMap: Record<SeverityEnum, { iconName: string; color: string }> = {
  [SeverityEnum.ERROR]: { color: 'var(--red-600)', iconName: 'farExclamationCircle' },
  [SeverityEnum.WARNING]: { color: 'var(--yellow-300)', iconName: 'farExclamationTriangle' },
  [SeverityEnum.INFO]: { color: 'var(--blue-600)', iconName: 'farInfoCircle' },
};

const Notification = ({ data }: NotificationInterface) => {
  const refElement = useRef<HTMLDivElement>(null);

  useNotificationVisibilityObserver(refElement, data.id);

  const timeLabel = DateTime.fromJSDate(new Date(data.createdAt)).toFormat('HH:mm:ss');
  const { color, iconName } = styleSeverityMap[data.severity] || {
    color: 'var(--blue-600)',
    iconName: 'farInfoCircle',
  };

  return (
    <div className={styles.notification} ref={refElement}>
      <div className={styles.notification__title}>
        <Icon size='sm' name={iconName} style={{ color }} />
        <h4>
          <Title data={data} />
        </h4>
      </div>
      <div className={styles.notification__statuses}>{timeLabel && <span>{timeLabel}</span>}</div>
    </div>
  );
};

export default React.memo(Notification);
