import React, { CSSProperties } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface ProgressBarInterface {
  progress: number;
  className?: string;
  lineClassName?: string;
  text?: string;
  style?: CSSProperties;
}

const ProgressBar = ({ progress, className, lineClassName, text, style }: ProgressBarInterface) => {
  const filteredProgress = progress < 0 ? 0 : progress > 100 ? 100 : progress;

  return (
    <div className={cn(styles.progressBar, className)} style={style}>
      <div className={cn(styles.progressBar__line, lineClassName)} style={{ width: `${filteredProgress}%` }}></div>
      {text && <span className={styles.progressBar__text}>{text}</span>}
    </div>
  );
};

export default React.memo(ProgressBar);
