import { useCallback } from 'react';

import { useIsVisible } from 'hooks/useIsVisible';
import { useNotificationsContext } from 'modules/Notifications/domain/context';

export const useNotificationVisibilityObserver = (targetNodeRef: React.RefObject<HTMLDivElement>, id: number) => {
  const { addViewedNotification } = useNotificationsContext();

  const handleVisibilityChange = useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        addViewedNotification(id);
      }
    },
    [addViewedNotification, id]
  );

  useIsVisible(targetNodeRef, handleVisibilityChange);
};
