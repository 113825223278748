import { useEffect, useRef } from 'react';

type OptionType = {
  shouldCallImmediately?: boolean;
  deps?: any[];
  disabled?: boolean;
};
const defaultOptions: OptionType = {
  shouldCallImmediately: false,
  deps: [],
  disabled: false,
};
export const useInterval = (callback: () => void, delay: number, options: OptionType = defaultOptions) => {
  const { shouldCallImmediately = false, deps = [], disabled = false } = options;

  const intervalRef = useRef<NodeJS.Timeout>();
  const callbackRef = useRef<() => void>(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (!disabled) {
      shouldCallImmediately && callbackRef.current();
      intervalRef.current = setInterval(() => callbackRef.current(), delay);
    }
    return () => intervalRef.current && clearInterval(intervalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, shouldCallImmediately, disabled, ...deps]);

  return intervalRef;
};

export default useInterval;
