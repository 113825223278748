import React, { createContext, useContext } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';

import { AppContext, AppContextType } from 'contexts/AppContext';
import { AccountApiService } from 'services/AccountApiService';
import { ModelApiService } from 'services/ApiServices/ModelApiService';
import AccountInProductVerifier from 'services/AccountInProductVerifier';

import { AccountDataDto, UserRoles, AccountInvitationDto, OptionsResponseDto, DomainOptions } from './types';
import { AccountInfoData } from 'api/CailagateApi/api/client';
import {
  accounsadminAuthentication,
  checkIfUserIsSAdmin,
  getAllowedAccounts,
  getLoginLink,
  getRegisterLink,
  login,
  loginWithAccountPageRedirect,
  redirectToConversationCloud,
  register,
  getAccountInvitations,
  getCcOptions,
} from './utils';
import { isDev } from 'utils';

const ONLY_MY = true;

const accountInProductVerifier = new AccountInProductVerifier();

export type AuthContextType = {
  user?: AccountInfoData;
  userMail?: string;
  userId?: number;
  userFeatures?: string[];
  userPermissions?: string[];
  userHasFeature: (feature?: string) => boolean;
  userHasPermission: (permission?: string) => boolean;
  updateUserInfoData: (userInfoData: AccountInfoData) => Promise<void>;
  isAuthenticated: boolean;
  isUserSuperadmin: boolean;
  userHasServices: boolean;
  userRole: UserRoles;
  login: (redirectPathname?: string) => void;
  loginWithAccountPageRedirect: () => void;
  logout: (redirectPathname?: string) => void;
  register: (redirectPathname?: string) => void;
  getRegisterLink: (redirectPathname?: string) => string;
  getLoginLink: (redirectPathname?: string) => string;
  changeAccount: (accountId: number) => Promise<void>;
  allowedAccounts?: AccountDataDto[];
  isSystemAccount: boolean;
  invitations?: AccountInvitationDto[];
  ccAccountId?: number;
  ccOptions?: OptionsResponseDto;
  redirectToSelectAccount: () => void;
};

export const AuthContext = createContext({} as AuthContextType);

interface AuthContextProviderProps {}

class AuthContextProviderState {
  isInitialized: boolean = false;
  userHasServices: boolean = false;
  isUserSuperadmin: boolean = false;
  user?: AccountInfoData = undefined;
  userId?: number = undefined;
  userMail?: string = undefined;
  userFeatures?: string[] = undefined;
  userPermissions?: string[] = undefined;
  allowedAccounts?: AccountDataDto[] = undefined;
  invitations?: AccountInvitationDto[] = undefined;
  ccAccountId?: number = undefined;
  ccOptions?: OptionsResponseDto = undefined;
}

const cookies = new Cookies();

export default class AuthContextProvider extends React.Component<AuthContextProviderProps, AuthContextProviderState> {
  static contextType = AppContext;
  context!: AppContextType;
  state = new AuthContextProviderState();

  componentDidMount() {
    this.checkUserAuth();
  }

  componentDidUpdate(prevProps: Readonly<AuthContextProviderProps>, prevState: Readonly<AuthContextProviderState>) {
    if (prevState.user?.language !== this.state.user?.language) {
      this.context.changeLanguage(this.state.user?.language);
    }
  }

  logout = () => {
    this.setState(prevState => ({ ...prevState, user: undefined }));
    redirectToConversationCloud(`/c/logout?redirectUrl=${window.location.origin}`);
  };

  redirectToSelectAccount = () => {
    window.location.href = isDev()
      ? 'https://localhost:3001/c/select-account'
      : `${window.location.origin}/c/select-account`;
  };

  checkUserAuth = async () => {
    let accounsadminUserInfo: any = undefined;
    let allowedAccounts: AccountDataDto[] = [];
    let userFeatures: string[] | undefined;
    let userMail: string | undefined;
    let userId: number | undefined;
    let userPermissions: string[] | undefined;
    let isUserSuperadmin: boolean = false;
    let currentUser: AccountInfoData | undefined = undefined;
    let userHasServices: boolean = false;
    let invitations: AccountInvitationDto[] = [];
    let ccAccountId: number | undefined;
    let ccOptions: OptionsResponseDto | undefined;
    this.context.setLoading(true);
    this.setState({
      isInitialized: false,
      userHasServices: false,
      user: undefined,
      userFeatures,
      userPermissions,
      isUserSuperadmin,
      allowedAccounts,
      ccAccountId,
      ccOptions,
    });
    try {
      ccOptions = await getCcOptions();
      if (!ccOptions?.domains) return;
    } catch (error) {
      console.error(error);
    }
    try {
      accounsadminUserInfo = await accounsadminAuthentication();
      userFeatures = accounsadminUserInfo?.userData?.features as string[] | undefined;
      userPermissions = accounsadminUserInfo?.userData?.permissions as string[] | undefined;
      userMail = accounsadminUserInfo?.userData?.login as string | undefined;
      userId = accounsadminUserInfo?.userData?.userId as number | undefined;
      ccAccountId = accounsadminUserInfo?.userData?.accountId as number | undefined;
      isUserSuperadmin = checkIfUserIsSAdmin(userPermissions, ccAccountId);
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        accountInProductVerifier.errorInterceptor(error, ['account.access.denied', 'account.not_selected']);
      }
    }

    try {
      if (!!ccOptions && !!userId) {
        const hostname = isDev() ? new URL(process.env.REACT_APP_RESTAPI_PROXY)?.hostname : window.location.hostname;
        const currentDomainData = Object.values(ccOptions?.domains).find(domain => domain.domain === hostname);

        allowedAccounts = await getAllowedAccounts(userId, currentDomainData?.product || '');

        accountInProductVerifier.checkAccountInfo({
          accountData: {
            internal: accounsadminUserInfo?.userData?.internal,
            accountOwner: accounsadminUserInfo?.userData?.accountOwner,
            accountId: accounsadminUserInfo?.userData?.accountId,
          },
          domainData: currentDomainData || ({} as DomainOptions),
          allowedAccountsData: allowedAccounts,
        });
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        accountInProductVerifier.errorInterceptor(error, ['account.access.denied', 'account.not_selected']);
      }
    }

    try {
      if (!!userId) {
        invitations = await getAccountInvitations(userId);
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        accountInProductVerifier.errorInterceptor(error, ['account.access.denied', 'account.not_selected']);
      }
    }

    try {
      if (!isUserSuperadmin) {
        const accountApiService = this.context.diContainer.get(AccountApiService);
        const payload = await accountApiService.ensureAccount();
        currentUser = payload.data;
      }
    } catch (error) {
      console.error(error);
    }

    try {
      if (!!currentUser) {
        const modelApiService = this.context.diContainer.get(ModelApiService);
        const {
          data: { records: userServices },
        } = await modelApiService.getPagedModels(currentUser.accountId.toString(), ONLY_MY);
        userHasServices = userServices.length > 0;
      }
    } catch (error) {
      console.error(error);
    }

    this.setState({
      isInitialized: true,
      user: currentUser,
      userFeatures,
      userPermissions,
      isUserSuperadmin,
      allowedAccounts,
      userHasServices,
      userMail,
      userId,
      invitations,
      ccAccountId,
      ccOptions,
    });
    this.context.setLoading(false);
  };

  userHasFeature = (feature?: string) => {
    if (!feature) return false;
    feature = feature.toLowerCase();
    return !!this.state.userFeatures?.some(userFeature => userFeature.toLowerCase() === feature);
  };

  userHasPermission = (permission?: string) => {
    if (!permission) return false;
    permission = permission.toLowerCase();
    return !!this.state.userPermissions?.some(userPermission => userPermission.toLowerCase() === permission);
  };

  updateUserInfoData = async (userInfoData: AccountInfoData) => {
    const accountApiService = this.context.diContainer.get(AccountApiService);
    const { data } = await accountApiService.updateAccountData(userInfoData);
    this.setState({ user: data });
  };

  changeAccount = async (accountId: number) => {
    cookies.set('SELECTED_ACCOUNT', String(accountId), { path: '/' });
    this.checkUserAuth();
  };

  render() {
    const {
      user,
      userId,
      userMail,
      userFeatures,
      userPermissions,
      userHasServices,
      isInitialized,
      isUserSuperadmin,
      allowedAccounts,
      invitations,
      ccAccountId,
      ccOptions,
    } = this.state;
    if (!isInitialized) return null;
    const isAuthenticated = !!user || isUserSuperadmin;
    const userRole = isUserSuperadmin ? 'superadmin' : 'user';

    return (
      <AuthContext.Provider
        value={{
          user,
          userMail,
          userId,
          userFeatures,
          userPermissions,
          userHasFeature: this.userHasFeature,
          userHasPermission: this.userHasPermission,
          updateUserInfoData: this.updateUserInfoData,
          isAuthenticated,
          isUserSuperadmin,
          userHasServices,
          userRole,
          login,
          loginWithAccountPageRedirect,
          logout: this.logout,
          register,
          getRegisterLink,
          getLoginLink,
          changeAccount: this.changeAccount,
          allowedAccounts: allowedAccounts,
          isSystemAccount: this.context.isSystemAccount,
          invitations,
          ccAccountId,
          ccOptions,
          redirectToSelectAccount: this.redirectToSelectAccount,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export const useAuthContext = () => useContext(AuthContext);
