import { EntityTypes } from '../../domain/types';
import { WORKSPACE_BASE_PATH } from '../../external';

export const getEntityLink = (data: EntityTypes): string | undefined => {
  if (!data || !data.name || !data.key) return undefined;
  switch (data.name) {
    case 'model': {
      return `/${WORKSPACE_BASE_PATH}/model/${data.key?.accountId}/${data.key?.modelId}`;
    }
    case 'resource_group': {
      return `/${WORKSPACE_BASE_PATH}/resource-group/${data.key?.groupOwnerId}/${data.key?.groupName}`;
    }
    case 'resource_group_server': {
      return `/${WORKSPACE_BASE_PATH}/resource-group/${data.key?.groupOwnerId}/${data.key?.groupName}/servers`;
    }
    case 'resource_quota': {
      return `/${WORKSPACE_BASE_PATH}/resource-group/${data.key?.groupOwnerId}/${data.key?.groupName}/quotas`;
    }
    case 'instance': {
      //TODO add instance selection by query param when it will be supported
      return `/${WORKSPACE_BASE_PATH}/model/${data.key?.accountId}/${data.key?.modelId}/diagnostic/monitoring`;
    }
  }
  return undefined;
};
