export const FEATURES = Object.freeze({
  MLP_MODEL_ADMIN: 'mlp.model_admin',
  MLP_PUBLIC_MODEL_ADMIN: 'mlp.public_model_admin',
  MLP_BILLING_NO_BLOCKING: 'mlp_billing.no_blocking',
  MLP_SHARED_POOL_ADMIN: 'mlp.shared_pool_admin',
});

export const PERMISSIONS = Object.freeze({
  MLP_ADMIN: 'MLP_ADMIN',
  MLP_DEVELOPER: 'MLP_DEVELOPER',
  MLP_USER: 'MLP_USER',
});
