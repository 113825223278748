import { useRef, useCallback } from 'react';

const useSetRef = <TYPE extends unknown>() => {
  const refSet = useRef<Set<TYPE>>(new Set());

  const add = useCallback((item: TYPE) => {
    refSet.current.add(item);
  }, []);

  const has = useCallback((item: TYPE) => {
    return refSet.current.has(item);
  }, []);

  const getAll = useCallback(() => {
    return Array.from(refSet.current);
  }, []);

  const clear = useCallback(() => {
    refSet.current.clear();
  }, []);

  return { add, has, getAll, clear };
};

export default useSetRef;
