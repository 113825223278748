import React, { useEffect, useState } from 'react';

import cn from 'classnames';

import { TreeContextProviderComponent, useTreeContext } from './contexts/TreeContext';
import { TreeNodeType } from './types';

export interface TreeProps<DATA_TYPE extends unknown> {
  data?: TreeNodeType<DATA_TYPE>[];
  maxDepth?: number;
  depthClassNameMap?: Record<number, string>;
  renderNode: (
    data: DATA_TYPE,
    options: { toggleOpen: () => void; isOpen: boolean; hasChildren: boolean }
  ) => React.ReactNode;
}

const Tree = <DATA_TYPE extends unknown>({ data, renderNode, maxDepth, depthClassNameMap }: TreeProps<DATA_TYPE>) => {
  if (!data || !Array.isArray(data)) return null;

  return (
    <TreeContextProviderComponent renderNode={renderNode} maxDepth={maxDepth} depthClassNameMap={depthClassNameMap}>
      {data.map(node => (
        <TreeNode key={node.id} node={node} depth={1} parentOpen={true} />
      ))}
    </TreeContextProviderComponent>
  );
};

interface TreeNodeProps<DATA_TYPE extends unknown> {
  node: TreeNodeType<DATA_TYPE>;
  depth: number;
  parentOpen: boolean;
}

const TreeNode = <DATA_TYPE extends unknown>({ node, depth, parentOpen }: TreeNodeProps<DATA_TYPE>) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasChildren = Array.isArray(node.children) && node.children.length > 0 && depth < 3;
  const { renderNode, maxDepth, depthClassNameMap } = useTreeContext();

  const className = depthClassNameMap?.[depth];

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    if (!parentOpen) {
      setIsOpen(false);
    }
  }, [parentOpen]);

  if (maxDepth !== undefined && depth > maxDepth) {
    return null;
  }
  return (
    <>
      <div className={cn(className)} style={{ display: 'contents' }}>
        {renderNode(node.data, { toggleOpen, isOpen, hasChildren })}
        {isOpen && hasChildren && (
          <div className='ml-4'>
            {node.children!.map(child => (
              <TreeNode key={child.id} node={child} depth={depth + 1} parentOpen={isOpen} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Tree;
