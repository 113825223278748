import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';

import { TaskShortData } from 'api/CailagateApi/api/client';

import useVisibleLongPolling from 'hooks/useVisibleLongPolling';
import useSetRef from 'hooks/useSetRef';

import { useAuthContext } from '../external';
import { throttle } from 'lodash';
import useBackgroundTasksApi from './hooks';

export type BackgroundTasksContextType = {
  stopTask: (taskId: string) => Promise<void>;
  getTaskStatuses: () => Promise<void>;
  tasks: TaskShortData[];
  tasksMap: Record<string, TaskShortData>;
  addViewedTask: (taskId: string) => void;
  saveTasksAsViewed: () => Promise<void>;
};

export const BackgroundTasksContext = createContext({} as BackgroundTasksContextType);

interface BackgroundTasksContextProviderProps {}

const treeToMap = (data: TaskShortData[]): Record<string, TaskShortData> => {
  const map: Record<string, TaskShortData> = {};

  const traverse = (item: TaskShortData) => {
    map[item.jobId] = item;
    if (item.children) {
      item.children.forEach(traverse);
    }
  };

  data.forEach(traverse);
  return map;
};

const BackgroundTasksContextProvider: FC<BackgroundTasksContextProviderProps> = ({ children }) => {
  const [tasks, setTasks] = useState<TaskShortData[]>([]);

  const { markRead, stopTask, getTaskStatuses: getTaskStatusesApi } = useBackgroundTasksApi();

  const getTaskStatuses = useCallback(async () => {
    getTaskStatusesApi().then(data => {
      data && setTasks(data);
    });
  }, [getTaskStatusesApi]);

  const { add: addViewedTaskToSet, clear: clearViewedTaskSet, getAll: getAllViewedTask } = useSetRef<string>();

  const saveTasksAsViewed = useCallback(async () => {
    const taskIds = getAllViewedTask();
    if (!!taskIds?.length) {
      await markRead(taskIds);
      clearViewedTaskSet();
      await getTaskStatuses();
    }
  }, [clearViewedTaskSet, getAllViewedTask, getTaskStatuses, markRead]);

  const saveTasksAsViewedThrottled = useMemo(
    () => throttle(saveTasksAsViewed, 3_000, { leading: false, trailing: true }),
    [saveTasksAsViewed]
  );

  const addViewedTask = useCallback(
    async (id: string) => {
      addViewedTaskToSet(id);
      saveTasksAsViewedThrottled();
    },
    [addViewedTaskToSet, saveTasksAsViewedThrottled]
  );

  useVisibleLongPolling(getTaskStatuses, 4_000);

  const tasksMap = useMemo(() => {
    return treeToMap(tasks);
  }, [tasks]);

  return (
    <BackgroundTasksContext.Provider
      value={{
        stopTask,
        getTaskStatuses,
        tasks,
        tasksMap,
        addViewedTask,
        saveTasksAsViewed,
      }}
    >
      {children}
    </BackgroundTasksContext.Provider>
  );
};

export const BackgroundTasksContextProviderComponent: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuthContext();
  if (!isAuthenticated) return null;

  return <BackgroundTasksContextProvider>{children}</BackgroundTasksContextProvider>;
};

export const useBackgroundTasksContext = () => useContext(BackgroundTasksContext);
