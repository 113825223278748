import CailaLogo from 'img/caila-logo.svg';
import { useAppContext } from 'contexts/AppContext';
import TovieMLPLogo from 'modules/Header/img/TovieMLPLogo';

import styles from './styles.module.scss';

const Logo = () => {
  const { isTovieMLP } = useAppContext();
  if (isTovieMLP) return <TovieMLPLogo />;
  return (
    <div data-test-id='headerLogoLink' className={styles.logo}>
      <img src={CailaLogo} alt='mlp-logo' />
    </div>
  );
};

export default Logo;
