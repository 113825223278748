import React from 'react';
import Input, { InputInterface } from 'components/Input';
import { useController, Control, Path, UnpackNestedValue, PathValue, RegisterOptions } from 'react-hook-form';

interface ControlledInputInterface<FIELD_TYPE extends Record<string, any>> extends Partial<InputInterface> {
  name: Path<FIELD_TYPE>;
  title?: string;
  placeholder?: string;
  defaultValue?: UnpackNestedValue<PathValue<FIELD_TYPE, Path<FIELD_TYPE>>>;
  control: Control<FIELD_TYPE>;
  onKeyUp?: (e: KeyboardEvent, value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  h3Title?: string;
  rules?: RegisterOptions;
}

const ControlledInput = <FIELD_TYPE extends Record<string, any>>({
  name,
  control,
  defaultValue,
  title,
  placeholder,
  onKeyUp,
  onBlur,
  onFocus,
  h3Title,
  rules,
  hidden,
  type = 'text',
  ...props
}: ControlledInputInterface<FIELD_TYPE>) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name, control, defaultValue, rules });
  return (
    <>
      {h3Title && <h3 hidden={hidden}>{h3Title}</h3>}
      <Input
        {...props}
        {...field}
        innerRef={field.ref}
        title={title}
        errorText={invalid ? error?.message : ''}
        hasError={invalid}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        onFocus={onFocus}
        type={type}
        hidden={hidden}
      />
    </>
  );
};

export default React.memo(ControlledInput) as typeof ControlledInput;
