import React from 'react';

import { PersistentJobStatusEnum, TaskShortData } from 'api/CailagateApi/api/client';
import { EntityTypes } from 'modules/BackgroundTasks/domain/types';

import { useBackgroundTasksContext } from 'modules/BackgroundTasks/domain/context';
import { t } from 'localization';

interface WaitForLabelInterface {
  task: TaskShortData;
}

const WaitForLabel = ({ task }: WaitForLabelInterface) => {
  const { tasksMap } = useBackgroundTasksContext();

  if (task.persistentJobStatus !== PersistentJobStatusEnum.IDLE || !task.waitFor?.length) {
    return null;
  }

  const waitForTasks = task.waitFor
    .map(taskId => {
      return tasksMap[taskId];
    })
    .filter(Boolean)
    .map(task => {
      const operatedEntity = task.operatedEntity as EntityTypes;
      const operation = t(`BackgroundTasks:Task:Title:Operation:${task.operation}`);
      const entity = t(`BackgroundTasks:Task:Title:Operation:${operatedEntity.name}`);
      const name = task.operatedEntityName || '';

      return `"${operation} ${entity} ${name}"`;
    });

  if (!waitForTasks.length) {
    return null;
  }

  return <span>{t('BackgroundTasks:Task:WaitForLabel', { tasks: waitForTasks.join(', ') })}</span>;
};

export default React.memo(WaitForLabel);
