import React from 'react';

import Task from '../Task';
import Tree from 'components/Tree';

import { TaskShortData } from 'api/CailagateApi/api/client';
import { buildTree } from './utils';

import styles from './styles.module.scss';

interface TasksListInterface {
  tasks: TaskShortData[];
}

const depthClassNameMap = {
  1: styles.treeNode,
};

const TasksList = ({ tasks }: TasksListInterface) => {
  const tree = buildTree(tasks);

  return (
    <Tree
      data={tree}
      depthClassNameMap={depthClassNameMap}
      renderNode={(task, { isOpen, toggleOpen, hasChildren }) => (
        <Task task={task} isOpen={isOpen} toggleOpen={toggleOpen} hasChildren={hasChildren} />
      )}
    />
  );
};

export default React.memo(TasksList);
