import { createContext, useContext } from 'react';

export type RenderNodeType<DATA_TYPE> = (
  data: DATA_TYPE,
  options: { toggleOpen: () => void; isOpen: boolean; hasChildren: boolean }
) => React.ReactNode;

export interface TreeContextType<DATA_TYPE extends unknown> {
  renderNode: RenderNodeType<DATA_TYPE>;
  maxDepth?: number;
  depthClassNameMap?: Record<number, string>;
}

export const TreeContext = createContext<TreeContextType<any>>({} as TreeContextType<any>);

interface TreeContextProviderProps<DATA_TYPE extends unknown> {
  renderNode: RenderNodeType<DATA_TYPE>;
  maxDepth?: number;
  children: React.ReactNode;
  depthClassNameMap?: Record<number, string>;
}

export const TreeContextProviderComponent = <DATA_TYPE extends unknown>({
  children,
  renderNode,
  maxDepth,
  depthClassNameMap,
}: TreeContextProviderProps<DATA_TYPE>) => {
  return (
    <TreeContext.Provider
      value={{
        renderNode,
        maxDepth,
        depthClassNameMap,
      }}
    >
      {children}
    </TreeContext.Provider>
  );
};

export const useTreeContext = () => useContext(TreeContext);
