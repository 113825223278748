import React from 'react';
import { Link } from 'react-router-dom';

import { UINotificationData } from 'api/CailagateApi/api/client';
import { EntityTypes } from 'modules/Notifications/domain/types';
import i18n, { t } from 'localization';
import { getEntityLink } from '../../utils';

interface TitleInterface {
  data: UINotificationData;
}

const Title = ({ data }: TitleInterface) => {
  if (!!data.textMessage) {
    return <>{data.textMessage}</>;
  }

  if (!data.entity) return null;
  const operatedEntity = data.entity as EntityTypes;
  const operation = data.operationType ? t(`BackgroundTasks:Task:Title:Operation:${data.operationType}`) : '';
  const entity = t(`BackgroundTasks:Task:Title:Operation:${operatedEntity.name}`);
  const name = data.entityDisplayName || '';
  const link = getEntityLink(operatedEntity);

  const reason =
    !!data.operationType && i18n.exists(`BackgroundTasks:Task:Title:Operation:${data.operationType}:Reason`)
      ? t(`BackgroundTasks:Task:Title:Operation:${data.operationType}:Reason`)
      : '';
  const reasonText = reason ? ` ${reason}` : '';

  return link ? (
    name ? (
      <>
        {`${operation} ${entity} `}
        <Link to={link}>{name}</Link>
        {reasonText}
      </>
    ) : (
      <>
        {`${operation} `}
        <Link to={link}>{entity}</Link>
        {reasonText}
      </>
    )
  ) : (
    <>{`${operation} ${entity} ${name}${reasonText}`}</>
  );
};

export default React.memo(Title);
