import { createContext, FC, useCallback, useContext, useMemo } from 'react';

import { UINotificationData } from 'api/CailagateApi/api/client';

import { useNotifications } from './hooks/useNotifications';
import useSetRef from 'hooks/useSetRef';
import { throttle } from 'lodash';

export type NotificationsContextType = {
  getNotifications: () => Promise<void>;
  notifications: UINotificationData[];
  addViewedNotification: (id: number) => Promise<void>;
  readNotifications: (ids: number[]) => Promise<void>;
  saveNotificationAsViewed: () => Promise<void>;
};

export const NotificationsContext = createContext({} as NotificationsContextType);

interface NotificationsContextProviderProps {}

const NOTIFICATIONS_POLLING_DELAY = 5_000;

const NotificationsContextProvider: FC<NotificationsContextProviderProps> = ({ children }) => {
  const { notifications, readNotifications, getNotifications } = useNotifications({
    pollingDelay: NOTIFICATIONS_POLLING_DELAY,
  });

  const {
    add: addViewedNotificationToSet,
    clear: clearViewedNotificationSet,
    getAll: getAllViewedNotification,
  } = useSetRef<number>();

  const saveNotificationAsViewed = useCallback(async () => {
    const notificationIds = getAllViewedNotification();
    if (!!notificationIds?.length) {
      await readNotifications(notificationIds);
      clearViewedNotificationSet();
      await getNotifications();
    }
  }, [clearViewedNotificationSet, getAllViewedNotification, getNotifications, readNotifications]);

  const saveNotificationAsViewedThrottled = useMemo(
    () => throttle(saveNotificationAsViewed, 3_000, { leading: false, trailing: true }),
    [saveNotificationAsViewed]
  );

  const addViewedNotification = useCallback(
    async (id: number) => {
      addViewedNotificationToSet(id);
      saveNotificationAsViewedThrottled();
    },
    [addViewedNotificationToSet, saveNotificationAsViewedThrottled]
  );

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        getNotifications,
        addViewedNotification,
        readNotifications,
        saveNotificationAsViewed,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const NotificationsContextProviderComponent: React.FC = ({ children }) => {
  return <NotificationsContextProvider>{children}</NotificationsContextProvider>;
};

export const useNotificationsContext = () => useContext(NotificationsContext);
