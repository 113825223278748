import { Route, Link } from 'react-router-dom';
import { Icon } from '@just-ai/just-ui/dist/Icon';
import cn from 'classnames';

import Button from 'components/Button';
import Logo from './components/Logo';
import AccountMenu from './components/AccountMenu';
import BuildInfo from './components/BuildInfo';
import TabsNavigate from 'modules/Account/components/TabsNavigate';
import BalanceInfo from './components/BalanceInfo';
import BannerContainer from './components/BannerContainer';
import MobileScreenMenu from './components/MobileScreenMenu';
import { SelectAccountButton } from './components/SelectAccountButton';

import ImmersLogo from './img/ImmersLogo';

import { useMediaQuery } from 'hooks/useMediaQuery';
import { useAuthContext } from 'contexts/AuthContext';
import { useAppContext } from 'contexts/AppContext';

import { TabValue } from 'modules/Account/pages/PrivateServicePage/types';
import { documentationLink } from 'App';
import { t } from 'localization';

import './style.scss';
import BackgroundTasks from './components/BackgroundTasks';
import Notifications from './components/Notifications';

export default function Header() {
  const { loginWithAccountPageRedirect, logout, user, isAuthenticated, isUserSuperadmin } = useAuthContext();
  const { isImmers } = useAppContext();

  const tabs: TabValue[] = user
    ? [
        {
          name: 'PagesTabs:Workspace',
          value: 'workspace',
          dataTestId: 'PagesTabs:Workspace',
        },
        {
          name: 'PagesTabs:Catalog',
          value: 'catalog',
          dataTestId: 'PagesTabs:Catalog',
        },
      ]
    : [
        {
          name: 'PagesTabs:Catalog',
          value: 'catalog',
          dataTestId: 'PagesTabs:Catalog',
        },
      ];
  const isMobileScreen = useMediaQuery('(max-width: 640px)');
  const landingPagePath = !isAuthenticated ? '/' : '/landing';

  return (
    <>
      <header className='caila-header'>
        <BannerContainer />
        <div className='caila-header__container'>
          <div className={cn('caila-header__block', isImmers && 'caila-header__block_immers')}>
            {isImmers && <ImmersLogo />}
            <Link data-test-id='AppHeader:Link:LandingPage' to={landingPagePath}>
              <Logo />
            </Link>
            <SelectAccountButton />
          </div>
          <BuildInfo />
          <div className='caila-header__btn-block'>
            {!isMobileScreen && (
              <>
                {isAuthenticated ? (
                  <>
                    {!isUserSuperadmin && (
                      <>
                        <BalanceInfo />
                        <Notifications />
                        <BackgroundTasks />
                        <Route path='/:modulePage'>
                          <TabsNavigate className='headerNavigationTabs' tabs={tabs} path='' paramName='modulePage' />
                        </Route>
                      </>
                    )}

                    <a
                      target='_blank'
                      className='caila-header__documentation_link'
                      rel='noreferrer noopener'
                      href={documentationLink}
                      data-test-id='AppHeader:Link:Documentation'
                    >
                      <Icon color='primary' name='farQuestionCircle' />
                    </a>
                    <AccountMenu logout={logout} userName={user?.shortName || ''} />
                  </>
                ) : (
                  <Route path='/:modulePage?'>
                    <TabsNavigate
                      className={cn(isImmers ? 'headerNavigationTabs_immers' : 'headerNavigationTabs')}
                      tabs={tabs}
                      path=''
                      paramName='modulePage'
                    />
                  </Route>
                )}

                {!isAuthenticated && (
                  <Button
                    data-test-id='AppHeader:LoginButton'
                    color='primary'
                    className={cn(isImmers && 'caila-header__button_immers')}
                    onClick={loginWithAccountPageRedirect}
                  >
                    {t('Header:Login')}
                  </Button>
                )}
              </>
            )}
            {isMobileScreen && <MobileScreenMenu />}
          </div>
        </div>
      </header>
    </>
  );
}
