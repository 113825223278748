import { useCallback } from 'react';

import { useDI } from 'contexts/AppContext';

import { NotificationApiService } from 'services/ApiServices/NotificationApiService';
import { AppLogger } from 'services/AppLogger';
import { useAuthContext } from 'modules/Notifications/external';

export const useNotificationsApi = () => {
  const notificationApi = useDI(NotificationApiService);
  const { user } = useAuthContext();

  const getNotifications = useCallback(async () => {
    if (!user) return;
    try {
      const { data } = await notificationApi.getNotifications(user?.accountId?.toString());
      return data;
    } catch (error) {
      AppLogger.log({ exception: error });
    }
  }, [notificationApi, user]);

  const readNotifications = useCallback(
    async (ids: number[]) => {
      if (!user) return;
      try {
        await notificationApi.readNotifications(user?.accountId?.toString(), { ids });
      } catch (error) {
        AppLogger.log({ exception: error });
      }
    },
    [notificationApi, user]
  );
  return { readNotifications, getNotifications };
};
