import { useEffect } from 'react';

const IntersectionObserverOptions = {
  threshold: 0.3,
  root: null,
  rootMargin: '0px',
};

export const useIsVisible = (
  targetNodeRef: React.RefObject<HTMLDivElement>,
  onVisibilityChange: (isVisible: boolean) => void
) => {
  useEffect(() => {
    if (!targetNodeRef.current) return;
    const observer = new IntersectionObserver(([entry]) => {
      onVisibilityChange(entry.isIntersecting);
    }, IntersectionObserverOptions);

    observer.observe(targetNodeRef.current);
    const unobserve = targetNodeRef.current;

    return () => {
      if (unobserve) {
        observer.unobserve(unobserve);
        observer.disconnect();
      }
    };
  }, [onVisibilityChange, targetNodeRef]);
};
