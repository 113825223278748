import { UINotificationData } from 'api/CailagateApi/api/client';
import { equals } from 'ramda';
import { useCallback, useState } from 'react';
import { useNotificationsApi } from '../useNotificationsApi';
import useVisibleLongPolling from 'hooks/useVisibleLongPolling';
import { useAuthContext } from 'modules/Notifications/external';

const DEFAULT_NOTIFICATIONS_POLLING_DELAY = 5_000;
export const useNotifications = (
  { pollingDelay }: { pollingDelay: number } | undefined = {
    pollingDelay: DEFAULT_NOTIFICATIONS_POLLING_DELAY,
  }
) => {
  const { isAuthenticated } = useAuthContext();
  const { getNotifications: getNotificationsApi, readNotifications } = useNotificationsApi();

  const [notifications, setNotifications] = useState<UINotificationData[]>([]);

  const getNotifications = useCallback(async () => {
    getNotificationsApi().then(data => {
      if (!data) return;
      setNotifications(prevData => {
        return !equals(prevData, data) ? data : prevData;
      });
    });
  }, [getNotificationsApi]);

  useVisibleLongPolling(getNotifications, pollingDelay, { disabled: !isAuthenticated });

  return { notifications, readNotifications, getNotifications };
};
