import React, { useRef } from 'react';
import { Icon } from '@just-ai/just-ui/dist/Icon';

import ProgressBar from 'components/ProgressBar';
import Title from './components/Title';
import WaitForLabel from './components/WaitForLabel';
import IconButton from 'components/IconButton';
import ErrorLabel from './components/ErrorLabel';

import { useTaskVisibilityObserver, useTaskStop, useTimeLabel } from './hooks';

import { shotJobStatusStyleMap } from 'modules/BackgroundTasks/domain/types';
import { TaskShortData, PersistentJobStatusEnum } from 'api/CailagateApi/api/client';
import { t } from 'localization';

import styles from './styles.module.scss';

interface TaskInterface {
  task: TaskShortData;
  isOpen: boolean;
  toggleOpen: () => void;
  hasChildren: boolean;
}

const Task = ({ task, isOpen, toggleOpen, hasChildren }: TaskInterface) => {
  const { loading: loadingStop, stop } = useTaskStop(task.jobId);

  const refElement = useRef<HTMLDivElement>(null);

  useTaskVisibilityObserver(refElement, task.jobId);

  const { iconColor, iconName } = shotJobStatusStyleMap[task.persistentJobStatus] || {
    iconColor: undefined,
    iconName: undefined,
  };

  const canceling = task.persistentJobStatus === PersistentJobStatusEnum.REVERTING;

  const taskIsInProgress =
    task.persistentJobStatus === PersistentJobStatusEnum.RUN ||
    task.persistentJobStatus === PersistentJobStatusEnum.IDLE;

  const timeLabel = useTimeLabel(task);

  if (task.persistentJobStatus === PersistentJobStatusEnum.REPLACED) return null;

  return (
    <div className={styles.task} ref={refElement}>
      <div className={styles.task__title}>
        {iconName && <Icon size='sm' name={iconName} style={{ color: iconColor || 'var(--primary)' }} />}
        <h4>
          <Title task={task} />
        </h4>
      </div>
      <div className={styles.task__statuses}>
        {canceling ? (
          <span>{t('BackgroundTasks:Task:Canceling')}</span>
        ) : (
          <>
            {timeLabel && <span>{timeLabel}</span>}
            <WaitForLabel task={task} />
            {taskIsInProgress && !!task.step && !!task.percentage && (
              <div className={styles.task__stepInfo}>
                <span style={{ width: '100%' }}>{task.step}</span>
                <ProgressBar
                  className={styles.task__progressBar}
                  lineClassName={styles.task__progressBarLine}
                  progress={task.percentage}
                />
              </div>
            )}
          </>
        )}
        <ErrorLabel message={task.errorMessage} />
      </div>
      {hasChildren && (
        <div className={styles.task__openIconWrap} onClick={toggleOpen}>
          <IconButton
            className={styles.task__openIcon}
            name='farChevronDown'
            size='sm'
            style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
          />
        </div>
      )}
      {taskIsInProgress && task.isInterruptible && (
        <IconButton
          className={styles.task__closeIcon}
          disabled={loadingStop}
          onClick={() => stop()}
          name='farTimesCircle'
          size='sm'
        />
      )}
    </div>
  );
};

export default React.memo(Task);
